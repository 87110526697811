import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShortNamePipe } from 'app/shared/pipes/short-name.pipe';
import { PhoneNumberPipe } from 'app/shared/pipes/phone-number.pipe';
import { VisibilityCheckDirective } from 'app/shared/directives/visibility-check.directive';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SubSink } from 'subsink';
import { GFilterGroup, GSortParam } from 'app/core/models/grid-filter.models';
import { environment } from 'environments/environment';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { AppConstants } from 'app/core/constants/app.constants';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { UserService } from 'app/modules/organization/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { User } from 'app/modules/organization/models/user';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CallCenterSharedService } from '../../services/call-center-shared.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CallTransferType } from '../../helpers/call-center-helpers';
import { SipInboundFunctionService } from '../../services/sip-inbound-function.service';

@Component({
  selector: 'dialer-agent-list',
  standalone: true,
  imports: [CommonModule, ShortNamePipe, PhoneNumberPipe, NgxSkeletonLoaderModule, VisibilityCheckDirective,
            ReactiveFormsModule, MatIconModule, MatButtonModule],
  templateUrl: './dialer-agent-list.component.html',
  styleUrl: './dialer-agent-list.component.scss',
  animations: [
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 1, transform: 'translateY(0)' })),
      // Enter transition (for showing the element)
      transition(':enter', [
        style({ height: '0px', opacity: 0, transform: 'translateY(-10px)' }),
        animate('300ms ease-out', style({ height: '*', opacity: 1, transform: 'translateY(0)' }))
      ]),
      // Leave transition (for hiding the element)
      transition(':leave', [
        style({ height: '*', opacity: 1, transform: 'translateY(0)' }),
        animate('300ms ease-in', style({ height: '0px', opacity: 0, transform: 'translateY(-10px)' }))
      ])
    ])
  ]
  
  
})
export class DialerAgentListComponent {

  @Input() session: any;

  @Output() closeTransferList = new EventEmitter<void>(); 

  @ViewChild('agents') private agentsBlock: ElementRef;

  @ViewChild('alphabetBar') alphabetBar: ElementRef;

  private subs: SubSink = new SubSink();

  public page = { count: 0, limit: 50, offset: 0, pageNumber: '0-0' };

  private sort: GSortParam[] = [{ colname: 'first_name', direction: 'asc' }];

  rows: User[] = [];

  public alphabets: string[] = ['#', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N',
    'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

  public selectedLetter: string = '';

  public selectedLettertobeFiltered: string = '';

  isLoadingMore: boolean = false;

  letterSearch: boolean = false;

  fileUrl: string = environment.imgURL;

  isLoading: boolean = false;

  bottomReached: boolean = false;

  currentCount: number = 0;

  totalCount: number = 0;

  agentSipUri: string;

  searchControl = new FormControl();

  public searchFilter: GFilterGroup[] = [];

  expandedRowId: number | null = null;

  transferType = CallTransferType;

  timeoutId: any;

  userId: number;

  constructor(
    private service: UserService, private toaster: ToastrService,
    private _cdr: ChangeDetectorRef,private renderer: Renderer2,
    private callCenterShared: CallCenterSharedService,
    private inboundService: SipInboundFunctionService) {
      this.userId = +localStorage.getItem(AppConstants.USER_ID);
    }

  ngOnInit(): void {
    // this.initialLoad = true;
    this.agentSipUri = localStorage.getItem(AppConstants.SIP_URI);
    this.getData();

    this.subs.add( 
      this.searchControl.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged()).subscribe(value=>{
          this.onSearchInput(value)
        }));
  }

  ngAfterViewInit(): void {
    let searchInput = document.getElementById('searchField') as HTMLInputElement;
    searchInput?.focus();
  }

  getData(letter?: string, filters : GFilterGroup[] = [], loadMore: boolean = false, offset: number = 0) {
    loadMore?this.isLoading = false:this.isLoading = true;
    let option = {
      sort: [{ colname: 'first_name', direction: 'asc' }],
      filters: filters,
      base_filters: []
    };
    if (letter && letter != "#") {
      filters.push(
        {conditions:[{ displayName: "First Name", colname: "first_name", condition: "startswith", value: letter, operator: "OR" }],   "operator": "AND"
      },
      );
    }
    this.service.filter(option,offset).subscribe({
      next:resp => {
        console.log('API response:', resp);
        if(resp.http_status == 200){
          this.totalCount = resp.data.total_count;
          this.currentCount += resp.data.result.length;
          if(loadMore){
            this.rows = [...this.rows, ...resp.data.result];
          }else{
            this.rows = resp.data.result;
          }
        }

        this.isLoading = false;
        this._cdr.markForCheck();
      },
      error: error => {
        this.toaster.error("Internal server error","Failed to get Agents");
        this.isLoading = false;
        this._cdr.markForCheck();
      }
    })
  }

  onSearchInput(val) {
    console.log('searchval',val)
    // this.isLoading = true;
    // this.isLoadingMore = true;
    this.selectedLetter = ''
    this.currentCount = 0;
    this.totalCount = 0;
    if (val) {
      this.rows = []
      this.letterSearch = true
      this.searchFilter= [{
        conditions: [
          {displayName: "First Name", colname: "first_name", condition: "contains", value: val, operator: "OR"},
          {displayName: "Agent Name", colname: "sip_username", condition: "contains", value: val, operator: "OR"}
        ],
        operator: "AND"                
      }];
      return  this.getData(null, this.searchFilter, false, 0);
    } else {
      this.searchFilter = []
      // this.rows = []
      // this.initialLoad = true
      return this.getData(null, [], false, 0);
    }
  }

  clearSearch(){
    this.searchControl.setValue('');
  }

  onAgentsScroll(AgentsBlock: HTMLElement){
    const scrollTop = AgentsBlock.scrollTop;
    const scrollHeight = AgentsBlock.scrollHeight;
    const offsetHeight = AgentsBlock.offsetHeight;
    const isScrolledToBottom = Math.floor(scrollTop + offsetHeight) >= scrollHeight - 1;

    console.log('isScrolledToBottom',isScrolledToBottom)
    console.log('bottomReached',this.bottomReached)

    if (isScrolledToBottom && !this.bottomReached) {
      this.bottomReached = true;
      if (!this.isLoading && (this.currentCount < this.totalCount)) {
        this.isLoading = true;
        let offset = this.currentCount;
        // API to get more Agents
        let filters = [];
        if (this.searchFilter) {
          filters = this.searchFilter;
        }
        this.getData(this.selectedLettertobeFiltered, filters, true, offset);
      }
      this._cdr.markForCheck();
    } else {
      this.bottomReached = false;
    }

  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  alphabetOnClick(alphabet) {
    this.selectedLettertobeFiltered = alphabet;
    this.selectedLetter = alphabet;
    this.totalCount = 0;
    this.currentCount = 0;
    this.getData(alphabet,[],false,0);
    this.scrollToTop();
  }

  scrollToTop() {
    console.log('scrollToTop');
    const element = this.agentsBlock.nativeElement;
    if (element.scrollTop !== 0) {
      element.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  onVisibilityChange(isVisible: boolean, index: number) {
    if (isVisible) {
      this.updateSelectedLetter(index);
     console.log('isVisible - ', isVisible, 'index - ', index);
    }
  }

  updateSelectedLetter(visibleIndex: number) {
    if (this.rows[visibleIndex]) {
      const firstName = this.rows[visibleIndex].first_name;
      const letter = firstName.charAt(0).toUpperCase();
      this.selectedLetter = letter >= 'A' && letter <= 'Z' ? letter : '#';
    }
    if (this.alphabetBar && this.selectedLetter) {
      const letterElement = this.alphabetBar.nativeElement.querySelector(`.letter[data-letter="${this.selectedLetter}"]`);
      if (letterElement) {
        const alphabetBarRect = this.alphabetBar.nativeElement.getBoundingClientRect();
        const letterRect = letterElement.getBoundingClientRect();
        const scrollOffset = letterRect.top - alphabetBarRect.top - (alphabetBarRect.height - letterRect.height) / 2;
        this.alphabetBar.nativeElement.scrollTo({
          top: this.alphabetBar.nativeElement.scrollTop + scrollOffset,
          behavior: 'smooth'
        });
      }
    }
  }

  onAlphabetScroll(alphabetBar: HTMLElement): void {
    const scrollTop = alphabetBar.scrollTop;
    const scrollHeight = alphabetBar.scrollHeight;
    const offsetHeight = alphabetBar.offsetHeight;
    const isScrolledToBottom = scrollTop + offsetHeight >= scrollHeight;

    if (isScrolledToBottom) {
      this.renderer.addClass(alphabetBar, 'topSide');
      this.renderer.removeClass(alphabetBar, 'botomSide');
      this.renderer.removeClass(alphabetBar, 'bothSide');

    } else {
      this.renderer.addClass(alphabetBar, 'botomSide');
      this.renderer.addClass(alphabetBar, 'bothSide');
    }

    if (scrollTop > 0 && !isScrolledToBottom) {
      this.renderer.removeClass(alphabetBar, 'botomSide');
    } else {
      this.renderer.removeClass(alphabetBar, 'bothSide');
    }
  }

  onMouseEnter(row, rowElement: HTMLElement,last:boolean=false){
    if(this.callCenterShared?.sipDetails?.agent_name != row.sip_username){
      this.timeoutId = setTimeout(() => {
        if(this.expandedRowId != row.id){
          this.expandTransferOptions(row, rowElement,last);
        }
      }, 300);
    }
  }

  onMouseLeave(){
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);// Clear the timeout to stop the action
      this.timeoutId = null;
      this.expandedRowId = null;
    }
  }

  expandTransferOptions(row: User, rowElement: HTMLElement, last:boolean) {
    if(this.callCenterShared?.sipDetails?.agent_name == row.sip_username){
      this.toaster.error("Can not transfer call to yourself");
      this.expandedRowId = null;
      return;
    }
    this.expandedRowId = this.expandedRowId === row.id ? null : row.id;
    if(this.expandedRowId){
      setTimeout(() => {
        rowElement.scrollIntoView({ 
          behavior: 'smooth', 
          block: last ? 'end' : 'nearest' 
        });
      }, 250);      
    }
  }

  onClose(){
    this.closeTransferList.emit();
  }

  transferCall(type: string, row: User) {
      this.inboundService.transferCall(this.session, type, row.id);
  }


}
