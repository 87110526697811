import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-image-crop',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatDialogActions, MatDialogContent, ImageCropperComponent, MatButtonModule, FormsModule],
  templateUrl: './image-crop.component.html',
  styleUrl: './image-crop.component.scss'
})
export class ImageCropComponent {
  croppedImage: any = '';
  scale: number = 1;
  transform: ImageTransform = {};

  show:boolean=false;

  constructor(
    public dialogRef: MatDialogRef<ImageCropComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { image: string, filename: string },
    private sanitizer: DomSanitizer, 
    private cdr:ChangeDetectorRef
  ) {}

  ngAfterContentInit(){
    setTimeout(() => {
      this.show=true;
    }, 200);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onCrop(): void {
    const file = this.base64ToFile(this.croppedImage, this.data.filename);
    this.dialogRef.close({"file":file, "url": URL.createObjectURL(file)});
  }

  base64ToFile(base64: string, filename: string): File {
    const arr = base64.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  onWheel(event: WheelEvent): void {
    event.preventDefault();
    if (event.deltaY > 0) {
      this.scale = Math.max(0.5, this.scale - 0.1);
    } else {
      this.scale = Math.min(3, this.scale + 0.1);
    }
    this.updateTransform();
  }

  updateTransform(): void {
    this.transform = {
      scale: this.scale
    };
  }

}
