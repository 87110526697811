import { AppConstants } from "app/core/constants/app.constants";
import { GFColumn, GFColumnTypes, GFilterParam, GridTab, SwitchTab, TabFilter } from "app/core/models/grid-filter.models";
import { PhoneNumberPipe } from "app/shared/pipes/phone-number.pipe";
import moment from "moment";



export class CallReportsDataHelper{

    constructor(public _phoneNumberPipe: PhoneNumberPipe){
    }

    static get FilterColumns():GFColumn[] {

        return [
            { displayName: 'Incoming Route', columnName: 'incoming_route_name', columnType: GFColumnTypes.string },
            { displayName: 'Company Name', columnName: 'company_name', columnType: GFColumnTypes.string },
            { displayName: 'Contact Name', columnName: 'company_users_name', columnType: GFColumnTypes.string },
            
            { displayName: 'Date', columnName: 'date_created', columnType: GFColumnTypes.date },
            { displayName: 'Date Between', columnName: 'date_created_between', columnType: GFColumnTypes.date_range },
            { displayName: 'From Number', columnName: 'call_from', columnType: GFColumnTypes.phone },
            { displayName: 'To Number', columnName: 'call_to', columnType: GFColumnTypes.phone },
            { displayName: 'Duration', columnName: 'duration', columnType: GFColumnTypes.number },
            {
                displayName: 'Status',
                columnName: 'status',
                columnType: GFColumnTypes.select,
                optionValueType: GFColumnTypes.ref,
                options: [
                    { displayName: 'Queued', value: 'queued' },
                    { displayName: 'Busy', value: 'busy' },
                    { displayName: 'No Answer', value: 'no-answer' },
                    { displayName: 'Completed', value: 'completed' },
                    { displayName: 'Canceled', value: 'canceled' },
                    { displayName: 'Failed', value: 'failed' },
                    { displayName: 'Blocked', value: 'blocked' }
                ]
            },
        ];
        
    }

    static get SearchFilters(): GFilterParam[] {
        return [
            {
                displayName: 'Combo Search', colname: '', condition: '', value: '',
                children: [
                    { displayName: 'Accepted By', colname: 'accepted_by_name', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.string },
                    { displayName: 'Called By', colname: 'company_users_name', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.string },
                    { displayName: 'Created By', colname: 'created_by_name', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.string },
                    { displayName: 'From Number', colname: 'call_from', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.phone },
                    { displayName: 'To Number', colname: 'call_to', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.phone },
                    { displayName: 'Incoming Route', colname: 'incoming_route_name', condition: 'contains', value: '', operator: 'OR', type: GFColumnTypes.string }
                ]
            },
            { displayName: 'From Number', colname: 'call_from', condition: 'contains', value: '', operator: 'AND', type: GFColumnTypes.phone },
            { displayName: 'To Number', colname: 'call_to', condition: 'contains', value: '', operator: 'AND', type: GFColumnTypes.phone },
            { displayName: 'Incoming Route', colname: 'incoming_route_name', condition: 'contains', value: '', operator: 'AND', type: GFColumnTypes.string }
        ];
    }

    static get DefaultFilters(): GFilterParam[] {
        return [
            {
                displayName: "Today", colname: 'date_created_between', condition: 'date_between',
                value: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
                operator: 'AND'
            },
            {
                displayName: "Yesterday", colname: 'date_created_between', condition: 'date_between',
                value: [moment().subtract(1, 'days').format('YYYY-MM-DD'), moment().subtract(1, 'days').format('YYYY-MM-DD')],
                operator: 'AND'
            },
            {
                displayName: "Current Week", colname: 'date_created_between', condition: 'date_between',
                value: [moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD')],
                operator: 'AND'
            },
            {
                displayName: "Last Week", colname: 'date_created_between', condition: 'date_between',
                value: [moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD'), moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DD')],
                operator: 'AND'
            },
            {
                displayName: "Current Month", colname: 'date_created_between', condition: 'date_between',
                value: [moment().startOf('month').startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
                operator: 'AND'
            },
            {
                displayName: "Last Month", colname: 'date_created_between', condition: 'date_between',
                value: [moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')],
                operator: 'AND'
            }
        ];
    }

    static get TabFilter(): TabFilter[]{
        const pipe = new PhoneNumberPipe();
        const sip = pipe.transform(localStorage.getItem(AppConstants.SIP_URI));
        return [
            {name: 'All Calls', filter:[
                { displayName: 'Inbound', colname: 'direction', condition: 'is', value: 'inbound', operator: 'OR', type: GFColumnTypes.string },
                { displayName: 'Outbound', colname: 'direction', condition: 'is', value: 'outbound', operator: 'OR', type: GFColumnTypes.string },
                ], selected:true
            },
            {name: 'Inbound', filter:[
                { 
                    conditions:[
                        { displayName: 'Inbound', colname: 'direction', condition: 'is', value: 'inbound', operator: 'AND', type: GFColumnTypes.string }
                    ],
                    operator:"OR"
                },
                { 
                    conditions:[
                        { displayName: 'Outbound', colname: 'direction', condition: 'is', value: 'outbound', operator: 'AND', type: GFColumnTypes.string },
                        { displayName: 'Call To', colname: 'formated_to_number', condition: 'is', value: sip, operator: 'AND', type: GFColumnTypes.string }
                    ],
                    operator:"OR"
                }
                    
                ]
            },
            {name: 'Oubound', filter:[
                { displayName: 'Outbound', colname: 'direction', condition: 'is', value: 'outbound', operator: 'AND', type: GFColumnTypes.string },
                { displayName: 'Call To', colname: 'formated_to_number', condition: 'not_equalto', value: sip, operator: 'AND', type: GFColumnTypes.string }
                ]
            }
        ]
    }
    
    static get DropDownFilters(): GFilterParam[] {
        const phoneNumberPipe = new PhoneNumberPipe(); // Manually instantiate the pipe
        const transformedPhoneNumber = phoneNumberPipe.transform(localStorage.getItem(AppConstants.SIP_URI));
        return [
            { displayName: "Completed", colname: 'status', condition: 'is', value: 'completed', operator: 'AND' },
            { displayName: "Failed", colname: 'status', condition: 'is', value: 'failed', operator: 'AND' },
            { displayName: "In Progress", colname: 'status', condition: 'is', value: 'in-progress', operator: 'AND' },
            { displayName: "Missed Calls", filters:[{
                conditions:[
                    { colname: 'direction', condition: 'is', value: 'inbound', operator: 'AND' },
                    { colname: 'is_missed_call', condition: 'is', value: 'true', operator: 'AND' }
                ], operator:"OR"
            },
            {
                conditions:[
                    { colname: 'direction', condition: 'is', value: 'outbound', operator: 'AND' },
                    { colname: 'formated_to_number', condition: 'is', value: transformedPhoneNumber, operator: 'AND' },
                    { colname: 'accepted_by_id', condition: 'not_equalto', value: parseInt(localStorage.getItem(AppConstants.USER_ID)), operator: 'AND' },
                ], operator:"OR"
            }] 
            }
        ];
    }

    static get CallHistoryByUser(): GFilterParam[]{
        return [
            { displayName: 'AcceptedBy', colname: 'accepted_by_id', condition: 'equalto', value: parseInt(localStorage.getItem(AppConstants.USER_ID)), operator: 'OR'},
            { displayName: 'CreatedBy', colname: 'created_by_id', condition: 'equalto', value: parseInt(localStorage.getItem(AppConstants.USER_ID)), operator: 'OR'},
            { displayName: 'NotifiedUser', colname: 'notifed_user', condition: 'contains', value: [parseInt(localStorage.getItem(AppConstants.USER_ID))], operator: 'OR'}
        ];
    }

    static get SortLookup(){
        return {
            date_created: 'date_created',
            duration: 'duration',
            suborg_name: 'suborg_name',
            phone_number_name: 'phone_number_name',
            forward_number_name: 'forward_number_name'
        };
    }

    // dashboard data
    static get colors(): string[]{
        return ['#EE864C', '#EC81C1', '#3AD0FF', '#1EBE7B', '#6477D9', '#384244'];
    }

    static get backgroundColors(): string[]{
        return ['rgba(250, 102, 18, 0.05)', 'rgba(236, 129, 193, 0.05)', 'rgba(58, 208, 255, 0.05)', 'rgba(30, 190, 123, 0.05)', 'rgba(100, 119, 217, 0.05)', 'rgba(127, 127, 127, 0.05)'];
    }

    static get images(): string[]{
        return ['icons/dashboard-icons/today-call-icon.png', 'icons/dashboard-icons/yesterday-call-icon.png', 'icons/dashboard-icons/thisweek-call-icon.png', 'icons/dashboard-icons/lastweek-call-icon.png', 'icons/dashboard-icons/currentmonth-call-icon.png', 'icons/dashboard-icons/lastmonth-call-icon.png'];
    }
    
}